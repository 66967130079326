import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import Login from './Login';
import Access from './Access';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import Landing from './Landing';

import AssetDetails from './AssetDetails';
import HLSPlayer from './Player';
import shakaPlayer from './shakaPlayer';
import videoPlayer from './videoPlayer';
import Subscribe from './Subscribe';
import Constant from './Constant';
import ErrorNotFound from './ErrorNotFound';
import MyAccount from './MyAccount';
import About from './About';
import lcevcplayer from './LcevcPlayer';
import toggleplayer from './TogglePlayer';

import iOSsignup from './iOSSignUp';
import Androidsignup from './AndroidSignUp';
import Windowssignup from './WindowsSignUp';


import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, SignIn } from 'aws-amplify-react';
import  { Analytics, Storage, API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as subscriptions from './graphql/subscriptions';

Amplify.configure(awsconfig);

class App extends React.Component {

   constructor(props) {
      super(props);
      var self = this;
      this.state = {
         
      };
      this.onInit();
   }

   onInit() {
      var userName = localStorage.getItem('userName');
      
      }

  render() {
  
   return (

      <div>
         <Router>
            <Switch>
               <Route path="/" component={Login} exact />
               <Route path="/access" exact component={Access}  />
               <Route path="/forgotPassword" exact component={ForgotPassword}  />
               <Route path="/changePassword" exact component={ChangePassword}  />
               <Route path="/mtab" exact component={Landing}  />
               
               <Route path="/contentdetails" exact component={AssetDetails}  />
               <Route path="/hplayer" exact component={HLSPlayer}  />
               <Route path="/player" exact component={shakaPlayer}  />
               <Route path="/vplayer" exact component={videoPlayer}  />
               <Route path="/subscribe" exact component={Subscribe}  />
               <Route path="/account" exact component={MyAccount}  />
               <Route path="/about" exact component={About}  />
               <Route path="/lcevcplayer" exact component={lcevcplayer}  />
               <Route path="/togglelcevcplayer" exact component={toggleplayer}  />
               <Route path="/iOSsignup" exact component={iOSsignup}  />
               <Route path="/androidsignup" exact component={Androidsignup}  />
               <Route path="/windowssignup" exact component={Windowssignup}  />
               <Route path="*" component={ErrorNotFound} />
            </Switch>
         </Router>
          
      </div>


      );
}
}

export default App;